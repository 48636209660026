import React , { useContext, useState } from "react";
import { Container , Row , Spinner } from "react-bootstrap";
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js";

import { CustomerContext } from "../customer_context";
import CustomerOnboardFlow from "./customer_onboard_flow";
import CustomerOnboardPaymentStripe from "./customer_onboard_payment_stripe";

//Stripe promise
const stripe_promise = loadStripe( process.env.REACT_APP_FE_STRIPE_KEY , { stripeAccount: process.env.REACT_APP_STRIPE_MERCHANT_ID } )
	
export default function CustomerOnboardPayment()
{
	//Get customer context
	const customer_context = useContext( CustomerContext )

	//State
	const [loading , setLoading] = useState( false )
	
	//Variables
	const stripe_options = { clientSecret: customer_context.GetCustomerInfo( "stripe_secret" ) }

	//Return loading page if request has not respond
	if ( '' === customer_context.GetCustomerInfo( "stripe_secret" ) || true === loading ) 
	{
		//Return element
		return (
			<>	
				<Container>
					<Row className="d-flex justify-content-center">
						<Spinner animation="grow" />
					</Row>
				</Container>
			</>
		)
	}//end if statement

	//Return page after request has responded
	return(
		<>
			<CustomerOnboardFlow />
		
			<Elements options={stripe_options} stripe={stripe_promise}>
				<CustomerOnboardPaymentStripe setTrigger={() => setLoading( previous => !loading)} />
			</Elements>
		</>
	)
}