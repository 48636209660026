import axios from "axios";
import React , { useContext } from "react";
import { Button , Col , Container , Form , Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { PaymentElement , useElements , useStripe } from "@stripe/react-stripe-js"

import { ConfigBackendRoute , ConfigFrontendRoute } from "../config";
import { CustomerContext } from "../customer_context";

export default function CustomerOnboardPaymentStripe( props )
{
	//Context
	const customer_context = useContext( CustomerContext )
	const Callout = ({ message }) => {
		return (
		  <div className="alert alert-info callout">
			{message}
		  </div>
		);
	  };
	//Constant
	const payment_options = 
		{	fields: 
			{	billingDetails:
				{	address: 'never' 
				} 
			}
		}

	//Use 
	const navigate = useNavigate()

	//Variables
	const stripe = useStripe()
	const stripe_elements = useElements()

	async function SubmitCustomerPayment( event )
	{
		//Prevent page reload
		event.preventDefault()

		//Confirm setup 
		const temp_response = await stripe.confirmSetup
			(	{	confirmParams: 
					{	payment_method_data:
						{	billing_details: 
							{	address: { city: null , country: 'SG' , line1: null , line2: null , postal_code: null , state: null }
							}
						}
					,	return_url: 'https://localhost:5000/customer/onboard/complete' 
					}
				,	elements: stripe_elements
				,	redirect: 'if_required'	//disables stripe redirection
				} 
			)
		
		//Check for error 
		if ( temp_response.error ) console.log( temp_response.error )
		
		//Set loading page
		props.setTrigger( true )
		
		//Complete customer onboarding
		axios
		.post
		(	process.env.REACT_APP_BE_REMOTE_ADDRESS + ConfigBackendRoute.route_customer_complete
		,	{	merchant_id: process.env.REACT_APP_STRIPE_MERCHANT_ID
			,	session_id: customer_context.GetCustomerInfo( 'session_id' )
			,	stripe_payment: temp_response.setupIntent.payment_method
			}					
		)
		.then
		(	(response) =>
			{
				//Set customer payment details
				customer_context.SetCustomerInfo( 'stripe_payment' , JSON.stringify( response.data.data.stripe_payment ) )	

				//Unset loading page
				props.setTrigger( false )
			}
		)
		.catch( (error) => console.log( error ) )

		//Navigate to facial 
		navigate( ConfigFrontendRoute.route_onboard_complete , { replace: true } )
	}

	return(
		<>
			<Form onSubmit={SubmitCustomerPayment}>
			<Container className="mt-4 pt-4">
					<Row>


						<Callout message="By providing your payment details, it ensures a smooth transition to our full services in the future. Rest assured, you will NOT be charged during this trial" />


					</Row>
				</Container>
				<br></br>
				<Container className="p-5" id="form-customerOnboard-group">
					<PaymentElement options={payment_options} />
				</Container>
					
				<Container className="mt-4 pt-4">
					<Row>
						<Col className={ window.matchMedia( "(max-width: 480px)" ).matches ? "d-flex justify-content-center" : "d-flex justify-content-end"}>
							<Button id="onboard-next-button" type="submit">Next: Review</Button>
						</Col>
					</Row>
				</Container>
			</Form>
		</>
	)
}