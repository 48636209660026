import axios from "axios";
import React, { useContext, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Spinner,
  Stack,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { PiWarningCircleBold } from "react-icons/pi";

import { ConfigBackendRoute, ConfigFrontendRoute } from "../config";
import { CustomerContext } from "../customer_context";
import CustomerOnboardFlow from "./customer_onboard_flow";

export default function CustomerOnboardDetail() {
  // Contexts
  const customer_context = useContext(CustomerContext);
  // States
  const [selectedMobileOS, setSelectedMobileOS] = useState(
    customer_context.GetCustomerInfo("mobile_os")
  );
  const [alert, setAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [passwordError, setPasswordError] = useState(""); // State to store password error
  const [isValidEmail, setIsValidEmail] = useState(true); // New state for email validation

  // Use
  const navigate = useNavigate();

  function CapitalisedName(event) {
    // Split string
    let temp_name_split = String(event.target.value).split("");

    // Check length of array
    if (1 === temp_name_split.length) {
      // Capitalized first name
      event.target.value =
        String(event.target.value).charAt(0).toUpperCase() +
        String(event.target.value).slice(1);
    } else {
      // Capitalized each name
      temp_name_split = temp_name_split.map((name) => {
        // Capitalize first letter of each name
        return name.charAt(0).toUpperCase() + name.slice(1);
      });

      // Assign value
      event.target.value = temp_name_split.join("");
    } // end if statement
  }
  const axiosInstance = axios.create({
    withCredentials: false, // Disable sending credentials (cookies)
  });

  function SubmitCustomerDetail(event) {
    // Prevent page reloading
    event.preventDefault();

    // Enable loading page
    setLoading(previous => false);

    // Extract customer details
    const temp_name_first = event.target.temp_name_first.value;
    const temp_name_last = event.target.temp_name_last.value;

    const temp_mobile_os = event.target.mobile_os.value;
    const temp_number_mobile = event.target.mobile_number.value;
    const temp_email = event.target.email.value;
    const temp_secondary_email = event.target.secondary_email.value;
    const temp_password = event.target.password.value;
    const temp_password_validation = event.target.temp_password_validation.value; // Extract the reentered password

    // Check if the passwords match
    if (temp_password !== temp_password_validation) {
      setPasswordError("Passwords do not match");
      return; // Return early to prevent the form submission
    } else {
      setPasswordError(""); // Clear the password error message
    }


    const isValidEmail = /.+@jtc\.gov\.sg$/.test(temp_email);
    setIsValidEmail(isValidEmail);

    // Check if the email is not in the correct format
    if (!isValidEmail) {
      // Show alert
      setAlert(true);

      // Disable loading page
      setLoading(false);

      // Return early to prevent the form submission
      return;
    }
    // Store customer details
    customer_context.SetCustomerInfo("email", temp_email);
    customer_context.SetCustomerInfo("number_mobile", temp_number_mobile);
    customer_context.SetCustomerInfo("temp_name_first", temp_name_first);
    customer_context.SetCustomerInfo("temp_name_last", temp_name_last);

    customer_context.SetCustomerInfo("temp_mobile_os", temp_mobile_os);
    customer_context.SetCustomerInfo("secondary_email", temp_secondary_email);
    customer_context.SetCustomerInfo("password", temp_password);
    customer_context.SetCustomerInfo("temp_password_validation", temp_password_validation);

    // Scroll to the top of the screen
    window.scrollTo(0, 0);

    // Create customer and obtain setup intent details
    axiosInstance
      .post(process.env.REACT_APP_BE_REMOTE_ADDRESS + ConfigBackendRoute.route_customer_create, {
        email: temp_email,
        name_first: temp_name_first.trim(),
        name_last: temp_name_last.trim(),

        mobile_os: temp_mobile_os,
        secondary_email: temp_secondary_email,
        number_mobile: temp_number_mobile,
        password: btoa(temp_password),
        merchant_id: process.env.REACT_APP_STRIPE_MERCHANT_ID,
        session_id: customer_context.GetCustomerInfo("session_id"),
      })
      .then(response => {
        // Check if our required data exists display error
        if (true === response.data.success) {
          // Store client data
          customer_context.SetCustomerInfo("stripe_id", response.data.data.customer);
          customer_context.SetCustomerInfo("stripe_secret", response.data.data.client_secret);

          // Disable loading page
          setLoading(previous => false);

          // Navigate to the next page
          navigate(ConfigFrontendRoute.route_onboard_payment, { replace: true });
        } else {
          // Log
                    // Show alert
                    setAlert(previous => false);
          console.log("error encountered");

          // Disable loading page
          setLoading(previous => false);

          // Show alert
          setAlert(previous => true);
        } // end if statement
      })
      .catch(error => console.log(error));
  }

  function ValidateMobileNumber(event) {
    // Check for valid keypress
    if (
      "ArrowUp" === event.key ||
      "ArrowDown" === event.key ||
      "ArrowLeft" === event.key ||
      "ArrowRight" === event.key ||
      "Backspace" === event.key ||
      "Delete" === event.key ||
      "Tab" === event.key
    ) {
      // Return for a valid keypress
      return;
    } else {
      // Combine the current value with the latest value
      let temp_input = String(event.target.value + event.key);

      // Validate the first number
      // note: check exceeding to handle when the user selects all and inputs a new character
      if (1 === temp_input.length || 8 < temp_input.length)
        !/[8-9]/.test(event.key) && event.preventDefault();

      // Validate the remaining 7 numbers
      if (1 < temp_input.length && 8 >= temp_input.length)
        !/[0-9]/.test(event.key) && event.preventDefault();
    } // end if statement
  }

  function ValidateName(event) {
    // Check for valid keypress
    if (
      "ArrowUp" === event.key ||
      "ArrowDown" === event.key ||
      "ArrowLeft" === event.key ||
      "ArrowRight" === event.key ||
      "Backspace" === event.key ||
      "Delete" === event.key ||
      "Tab" === event.key
    ) {
      // Return for a valid keypress
      return;
    } else {
      // Validate regex
      !/[A-Z|a-z|\s]/.test(event.key) && event.preventDefault();
    } // end if statement
  }

  // Return loading page if the request has not responded
  if (loading) {
    return (
      <>
        <Container>
          <Row className="d-flex justify-content-center">
            <Spinner animation="grow" />
          </Row>
        </Container>
      </>
    );
  } // end if statement

  return (
    <>
      <CustomerOnboardFlow />

      <Form className="my-3" onSubmit={SubmitCustomerDetail}>
        <Container className="p-5" id="form-customerOnboard-group">
          <Row>
            <label className="mb-4" id="onboard-label-header">
              Personal Information
            </label>
          </Row>
          <Row className="mb-3" xs={1} md={3}>
            <Col className="mb-3">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                defaultValue={customer_context.GetCustomerInfo("temp_name_first")}
                name="temp_name_first"
                onKeyDown={event => ValidateName(event)}
                onKeyUp={event => CapitalisedName(event)}
                pattern="^[A-Za-z\s]{2,}"
                placeholder="First Name"
                required
                type="text"
              />
            </Col>
            <Col className="mb-3">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                defaultValue={customer_context.GetCustomerInfo("temp_name_last")}
                name="temp_name_last"
                onKeyDown={event => ValidateName(event)}
                onKeyUp={event => CapitalisedName(event)}
                pattern="^[A-Za-z\s]{2,}"
                placeholder="Last Name"
                required
                type="text"
              />
            </Col>
            <Col className="mb-3">
              <Form.Label>JTC Email (Username)</Form.Label>
              <Form.Control
                defaultValue={customer_context.GetCustomerInfo("email")}
                name="email"
                pattern="^.+@jtc\.gov\.sg$"
                placeholder="email@jtc.gov.sg"
                required
                type="email"
              />
            </Col>

            <Col className="mb-3">
              <Form.Label>Mobile Number</Form.Label>
              <Form.Control
                defaultValue={customer_context.GetCustomerInfo("number_mobile")}
                inputMode="numeric"
                maxLength={8}
                name="mobile_number"
                onKeyDown={event => ValidateMobileNumber(event)}
                pattern="[8-9]{1}[0-9]{7}"
                placeholder="91234567"
                required
                type="text"
              />
            </Col>
          </Row>
          <Row className="mb-3" xs={1} md={2}>
            <Col className="mb-3">
              <Form.Label>Mobile OS</Form.Label>
              <Form.Control
                defaultValue={customer_context.GetCustomerInfo("mobile_os")}
                name="mobile_os"
                as="select"
                type="text"
                value={selectedMobileOS}
                onChange={e => setSelectedMobileOS(e.target.value)}
              >
                <option value="iOS">iOS</option>
                <option value="Android">Android</option>
              </Form.Control>
            </Col>
            <Col className="mb-3">
              <Form.Label>iCloud/PlayStore Email</Form.Label>
              <Form.Control
                defaultValue={customer_context.GetCustomerInfo("secondary_email")}
                name="secondary_email"
                pattern="[^@]+@[^@]+\.[a-zA-Z]{2,}"
                placeholder="email@email.com"
                required
                type="email"
              />
            </Col>
            <Col className="mb-3">
              <Form.Label>Password</Form.Label>
              <Form.Control
                defaultValue={customer_context.GetCustomerInfo("password")}
                name="password"
                placeholder="Password"
                required
                type="password"
              />
            </Col>
            <Col className="mb-3">
              <Form.Label>Reenter Password</Form.Label>
              <Form.Control
                defaultValue={customer_context.GetCustomerInfo("temp_password_validation")}
                name="temp_password_validation"
                placeholder="Reenter Password"
                required
                type="password"
              />
              {passwordError && (
                <p className="text-danger">{passwordError}</p>
              )}
            </Col>
            <Col></Col>
          </Row>
          <Row>
            <Stack gap={2}>
              <Form.Check id="onboard-detail-checkbox-detail">
                <Form.Check.Input required type="checkbox" />
                <Form.Check.Label htmlFor="onboard-detail-checkbox-detail">
                  I confirm that the above details are correct.
                </Form.Check.Label>
              </Form.Check>
              <Form.Check id="onboard-detail-checkbox-disclose">
                <Form.Check.Input required type="checkbox" />
                <Form.Check.Label htmlFor="onboard-detail-checkbox-disclose">
                  I consent to JTC collecting, disclosing, or retaining my
                  personal data (including personal information and image of my
                  face) for the purpose of EOT App.
                </Form.Check.Label>
              </Form.Check>
              <Form.Check id="onboard-detail-checkbox-tnc">
                <Form.Check.Input required type="checkbox" />
                <Form.Check.Label htmlFor="onboard-detail-checkbox-tnc">
                  I acknowledge and agree to the{" "}
                  <a href="/privacy" rel="noopener noreferrer" target="_blank">
                    Privacy Statement
                  </a>{" "}
                  and{" "}
                  <a
                    href="https://www.jtc.gov.sg/terms-of-use"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Terms of Use
                  </a>
                  .
                </Form.Check.Label>
              </Form.Check>
            </Stack>
          </Row>
        </Container>

        <Container className="mt-4 pt-4">
          <Row>
            <Col
              className={
                window.matchMedia("(max-width: 480px)").matches
                  ? "d-flex justify-content-center"
                  : "d-flex justify-content-end"
              }
            >
              <Stack
                className="align-items-center d-flex justify-content-center"
                direction="vertical"
                gap={2}
              >
                <label className="fw-bold text-center px-3">
                  Clicking next will redirect you to a secured payment gateway
                  page for credit card enrollment
                </label>
                <Button id="onboard-next-button" type="submit">
                  Next: Payment Details
                </Button>
              </Stack>
            </Col>
          </Row>
        </Container>
      </Form>

      <Modal centered onHide={() => setAlert(previous => false)} show={alert}>
        <Modal.Header className="pt-3 pb-1" closeButton id="onboard-warning-modal-header">
          <Modal.Title>
            <Stack direction="horizontal" gap={1}>
              <PiWarningCircleBold color="#E46153" size={24} />
              <label id="onboard-warning-modal-header-text">Please Verify Your Details</label>
            </Stack>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>We found similar user credentials, please verify your name, email, and mobile number.</p>
        </Modal.Body>
      </Modal>
    </>
  );
}
