//Standard
import React from "react";

//Design
import "../style.css"

//External
import { Container , Row , Stack } from "react-bootstrap";

export default function Privacy() 
{
	//Return element
	return (
		<Container className="my-3 px-5 py-3" id="privacy-container">
			<Row className="mb-3">
				<Stack direction="vertical" gap={2}>
					<label className="fs-3 fw-bolder">Privacy Statement</label>
					<label className="fw-bold">Government agency privacy statement</label>
				</Stack>
			</Row>
			<Row>
				<ul>
					<li>This is a Government Agency digital service. We adopt the same principles relating to your privacy as other Government of Singapore digital service.</li>
					<br />
					<li>If you are only browsing this digital service, we do not capture data that allows us to identify you individually.</li>
					<br />
					<li>We may use "cookies", where a small data file is sent to your browser to store and track information about you when you enter our digital services. The cookie is used to track information such as the number of users and their frequency of use, profiles of users and their preferred digital services. While this cookie can tell us when you enter our digital services and which pages you visit, it cannot read data off your hard disk.</li>
					<br />
					<li>You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. This may prevent you from taking full advantage of the digital service.</li>
					<br />
					<li>
						The purpose of this Privacy Statement is to inform you of how we collect, use, disclose and process the following data and information in connection with your use of EOT app (“EOT App”) :
						<ul>
							<li>data and personal data relating to you; and</li>
							<li>any and all information you may provide to register for or use your Account on the EOT App.<br />(collectively, <b>"Data"</b>).</li>
						</ul>
					</li>
					<br />
					<li>
						We may request certain types of Data from you in connection with your use of the EOT App. Examples of the types of Data which we may request and collect include, but are not limited to:
						<ul>
							<li>information relating to your Account, such as your Account number;</li>
							<li>information about you from data source(s) approved by us;</li>
							<li>your contact information, including your telephone number, email and mailing addresses;</li>
							<li>your credit card information, including name, credit card number, expiry dates and CVV;</li>
							<li>Images of your face as captured by the relevant camera or face recognition function on your registered mobile device;</li>
							<li>such other information as may be provided by, or collected from you in connection with your use of the EOT App.</li>
						</ul>
					</li>
					<br />
					<li>
						Data collected from you may be used, disclosed, and processed by us for any, some, or all of the following purposes:
						<ol>
							<li>to process and facilitate your use of the EOT App, including registering and creating your Account, facilitating your use of any features or functionalities of the EOT App;</li>
							<li>to carry out your instructions or respond to any queries, feedback or complaints provided by (or purported to be provided by) you or on your behalf, or otherwise for the purposes of responding to or dealing with your interactions with us;</li>
						</ol>
						<ul>
							<li>to monitor and track your usage of the EOT App, in order to assist us in understanding user behaviour, trends and preferences, and in improving EOT App. For the avoidance of doubt, we may also collect, use, disclose and process such Data to create reports and produce statistics regarding the above for record-keeping and reporting or publication purposes (whether internally or externally);</li>
							<li>for the purposes of storing or creating backups of your Data for contingency or business continuity purposes;</li>
							<li>to deal with conflict of interests or investigate complaints in relation to your Account;</li>
							<li>to authenticate your identity through the use of facial recognition;</li>
							<li>to contact you or communicate with you on any matters relating to your use of the EOT App, including but not limited to the purposes set out above, via email, push notifications or such other forms of communication that we may introduce from time to time depending on the functionality of the EOT App;</li>
							<li>to contact you or communicate with you to facilitate the conduct of market research, feedbacks and surveys; and</li>
							<li>for any other purpose(s) that do not appear above where permissible under any written law<br />(collectively, the <b>"Purposes"</b>).</li>
						</ul>
					</li>
					<br />
					<li>If you choose to make an application, provide feedback or send us an email for which you provide us with personally identifiable data, you agree that where appropriate, we may share necessary data with other Government agencies, so as to improve the discharge of public functions, and to serve you in a most efficient and effective way, unless such sharing is prohibited by law. We will NOT share your personal data with non-Government entities, except where such entities have been authorised to carry out specific Government services.</li>
					<br />
					<li>Your personal data shall be used to assess, process and facilitate your application, feedback or query. If you withdraw your consent to use your personal data, we may not be able to complete assessing or processing your application, feedback or query.</li>
					<br />
					<li>For your convenience, we may also display to you data you had previously supplied us or other Government agencies. This will speed up the transaction and save you the trouble of repeating previous submissions. Should the data be out-of-date, please supply us the latest data. We will retain your personal data only as necessary for the effective delivery of public services to you.</li>
					<br />
					<li>To safeguard and protect your personal data, all electronic storage and transmission of personal data is secured with appropriate and available security technologies.</li>
					<br />
					<li>This digital service may contain links to non-Government digital services whose data protection and privacy practices may differ from ours. We are not responsible for the content and privacy practices of these other digital services and encourage you to consult the privacy notices of those digital services.</li>
					<br />
					<li>
						Please contact the Quality Service Manager at <a href="mailto:JTC_QSM@jtc.gov.sg">JTC_QSM@jtc.gov.sg</a>:
						<ul>
							<li>For any enquires or feedback on our data protection policies and procedures,</li>
							<li>If you require more information on or access to the data which you have earlier provided to us.</li>
						</ul>
					</li>
					<br />
					<li>
						If you do not wish us to share your personal data or continue to use for personal data, please let us know in writing to <a href="mailto:askjtc@jtc.gov.sg">askjtc@jtc.gov.sg</a> or the following address:
						<br /><br />
						JTC Corporation <br />
						The JTC Summit <br />
						8 Jurong Town Hall Road <br />
						Singapore 609434 <br />
					</li>
				</ul>
			</Row>
		</Container>
	)
}