export const ConfigBackendRoute =
	{	route_customer_complete: "/customer/account/complete"
	,	route_customer_create: "/customer/account/create"
	,	route_customer_delete: "/customer/account/delete"
	,	route_customer_facial: "/customer/account/facial"
	,	route_payment_retrieve: "/customer/payment/retrieve"
	}
	
export const ConfigCameraCapture = 
	{	camera_capture_width: 400	
	,	camera_capture_height: 514
	,	camera_display_width: 400
	,	camera_display_height: 514
	}

export const ConfigFrontendRoute =
	{	route_home_root: "/"
	,	route_onboard_complete: "/customer/onboard/complete"		
	,	route_onboard_detail: "/customer/onboard/detail"		
	,	route_onboard_facial: "/customer/onboard/facial"		
	,	route_onboard_payment: "/customer/onboard/payment"	
	}
