import axios from "axios";
import React , { useCallback , useContext , useRef , useState } from "react";
import { Button , Col , Container , Form , Image , Modal , Row , Stack , Spinner } from "react-bootstrap";
import Webcam from "react-webcam"
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid"
import { PiWarningCircleBold } from "react-icons/pi"

import facial from '../asset/image/facial.png'
import facial_correct_1 from '../asset/image/facial_correct_1.png'
import facial_correct_2 from '../asset/image/facial_correct_2.png'
import facial_correct_3 from '../asset/image/facial_correct_3.png'
import facial_correct_4 from '../asset/image/facial_correct_4.png'
import facial_correct_icon from '../asset/image/facial_correct_icon.png'
import facial_incorrect_1 from '../asset/image/facial_incorrect_1.png'
import facial_incorrect_2 from '../asset/image/facial_incorrect_2.png'
import facial_incorrect_3 from '../asset/image/facial_incorrect_3.png'
import facial_incorrect_4 from '../asset/image/facial_incorrect_4.png'
import facial_incorrect_icon from '../asset/image/facial_incorrect_icon.png'

import { ConfigBackendRoute , ConfigFrontendRoute , ConfigCameraCapture } from "../config";
import { CustomerContext } from "../customer_context";
import CustomerOnboardFlow from "./customer_onboard_flow";

export default function CustomerOnboardFacial()
{
	//Contexts
	const customer_context = useContext( CustomerContext )

	//References
	const camera_reference = useRef( null )

	//States
	const [alert, setAlert] = useState( false )
	const [capture , setCapture] = useState( false )
	const [image , setImage] = useState( facial )
	const [loading , setLoading] = useState( false )
	const [cameraEnv , setCameraEnv] = useState( false )	//false for facing user, true for facing outwards

	//Use
	const navigate = useNavigate()
	
	//Camera callback function for image capture
	const camera_capture = useCallback
		(	() => 
			{	
				//Capture image
				const temp_image = camera_reference.current.getScreenshot
					(	{	width: ConfigCameraCapture.camera_capture_width 
						,	height: ConfigCameraCapture.camera_display_height 
						} 
					)
				
				//Set image
				setImage( temp_image )
				
				//Save image
				customer_context.SetCustomerInfo( "image" , temp_image )
				
				//Reset capture state
				setCapture(false)
			}
			,	[camera_reference]
		)

	function GenerateSessionUuid()
	{
		//Instantiate date object
		let temp_date = new Date()
		
		//Return value
		return (
			temp_date.getFullYear() + '' + String( temp_date.getMonth() + 1 ).padStart( 2 , '0' ) + '' + String( temp_date.getDate() ).padStart( 2 , '0' )
		+	'-'
		+	String( temp_date.getHours() ).padStart( 2 , '0' ) + '' + String( temp_date.getMinutes() ).padStart( 2 , '0' ) + '' + String( temp_date.getSeconds() ).padStart( 2 , '0' )
		+	'-'
		+	uuidv4().slice( 0 , 13 )
		)
	}

	function HandleCameraOpen()
	{
		//Set capture mode
		setCapture( true )

		//Scroll to top of screen
		window.scrollTo( 0 , 0 )
	}

	function SubmitCustomerImage( event )
	{
		//Prevent page refresh
		event.preventDefault()

		//Set loading to show spinner
		setLoading( true )

		//Generate session ID
		let temp_sessionId = GenerateSessionUuid()
		
		//Store session ID
		customer_context.SetCustomerInfo( 'session_id' , temp_sessionId )
		
		//Scroll to top of screen
		window.scrollTo( 0 , 0 )

		//Send image to backend for processing
		axios
		.post
			(	process.env.REACT_APP_BE_REMOTE_ADDRESS + ConfigBackendRoute.route_customer_facial
			,	{	epoch: parseInt( Date.now() / 1000 )
				,	image: image
				,	merchant_id: process.env.REACT_APP_STRIPE_MERCHANT_ID
				,	session_id: temp_sessionId
				}
			)
		.then
			(	(response) => 
				{
					//Set loading to remove spinner
					setLoading( false )
					console.log(response);
					//Check if customer creation was successful
					if ( true === response.data.success ) 
					{	
						//Navigate to next page
						navigate( ConfigFrontendRoute.route_onboard_detail , { replace: true } )
					}
					else
					{
						//Show alert
						setAlert( previous => true )
					}//end if statement
				}
			)
		.catch
			(	(error) => 
				{
					//Set loading to false since error occured and remain at page
					setLoading( false )
				}
			)
	}
	
	//Return loading page during image upload
	if ( loading ) 
	{
		return (
			<>	
				<Container>
					<Row className="d-flex justify-content-center">
						<Spinner animation="grow" />
					</Row>
				</Container>
			</>
		)
	}

	return (
		<>
			<CustomerOnboardFlow />
			
			<Form className="my-3" onSubmit={SubmitCustomerImage}>
				<Container className="px-4 py-4" id="form-customerOnboard-group">
					{	( facial === image && !capture )
					?	<>
							<Row className="mb-4">
								<Stack gap={2}>
									<label id="onboard-label-header">Activate Facial Recognition</label>
									
									<label>Upload a recent photo of yourself to activate your Facial Recognition account</label>
									<label>Your data will only be used for verification purposes when you unlock your assigned Bicycle Locker with the Facial Recognition Device</label>

									<label>Please ensure that your photo meet the following guidelines.</label>
								</Stack>
							</Row>
							<Row className="mb-4" xs={1} md={2}>
								<Col className="mb-4">
									<Stack gap={4}>
										<Stack className="my-2 px-3 py-4" id="onboard-facial-stack-correct" gap={2}>
											<Image id="onboard-facial-stack-correct-icon" src={facial_correct_icon} />
											<Stack direction="horizontal" gap={2}>
												<Image src={facial_correct_1} />
												<label>Be in well-lit environment</label>
											</Stack>
											<Stack direction="horizontal" gap={2}>
												<Image src={facial_correct_2} />
												<label>Plain and white background</label>
											</Stack>
											<Stack direction="horizontal" gap={2}>
												<Image src={facial_correct_3} />
												<label>Facial features must be seen in full front view</label>
											</Stack>
											<Stack direction="horizontal" gap={2}>
												<Image src={facial_correct_4} />
												<label>Keep a neutral expression with mouth closed</label>
											</Stack>
										</Stack>
										
										<Stack className="my-2 px-3 py-4" id="onboard-facial-stack-incorrect" gap={2}>
											<Image id="onboard-facial-stack-incorrect-icon" src={facial_incorrect_icon} />
											<Stack direction="horizontal" gap={2}>
												<Image src={facial_incorrect_1} />
												<label>Avoid covering your facial features</label>
											</Stack>
											<Stack direction="horizontal" gap={2}>
												<Image src={facial_incorrect_2} />
												<label>No wearing of bandage, mask or sunglasses on your face</label>
											</Stack>
											<Stack direction="horizontal" gap={2}>
												<Image src={facial_incorrect_3} />
												<label>Avoid smiling with mouth opened or tongue out</label>
											</Stack>
											<Stack direction="horizontal" gap={2}>
												<Image src={facial_incorrect_4} />
												<label>Avoid cropping off shoulders and hair</label>
											</Stack>
										</Stack>
									</Stack>
								</Col>
								<Col>
									<Stack direction="vertical" gap={1}>
										<div className="align-items-center d-flex justify-content-center">
											<Image id="onboard-facial-image" src={image} />
										</div>
										<label className="text-center">Example Image</label>
									</Stack>	
								</Col>
							</Row>

							<Row className="align-items-center d-flex justify-content-center">
								<Button id="onboard-facial-button-capture" onClick={() => HandleCameraOpen()}>Open Camera</Button>
							</Row>
						</>
					:	<>
							<Row className="mb-3">
								<label id="onboard-label-header">Photo Capture</label>
							</Row>
							<Row>
								{	( capture )
								?	<>
										<Stack className="align-items-center d-flex justify-content-center mb-4" direction="vertical">
											{	(	/Android|Mobi|iPad|iPhone|Tablet/i.test( window.navigator.userAgent ) //Checks whether camera is loaded from mobile browser 
												||	( navigator.maxTouchPoints > 2 ) //checks for ipad
												)	
											?	<Button id="onboard-facial-button-camera" onClick={() => setCameraEnv( previous => !cameraEnv )}>
													<Webcam 
														disabled={!capture}
														mirrored={true}
														imageSmoothing={true}
														minScreenshotWidth={400}
														minScreenshotHeight={514}
														ref={camera_reference}
														screenshotFormat="image/jpeg" 
														screenshotQuality={1}
														style={{ maxWidth: "100%" , maxHeight: "100%" , width: "auto" , height: "auto" }}
														videoConstraints={{ facingMode: ( cameraEnv ) ? { exact: "environment" } : "user" }} 
													/>
												</Button>
											:	<Webcam 
													disabled={!capture}
													mirrored={true}
													minScreenshotWidth={400}
													minScreenshotHeight={514}
													ref={camera_reference}
													screenshotFormat="image/jpeg" 
													screenshotQuality={1}
													videoConstraints={{ facingMode: "user", width: ConfigCameraCapture.camera_capture_width , height: ConfigCameraCapture.camera_capture_height }} 
													width={ConfigCameraCapture.camera_display_width} height={ConfigCameraCapture.camera_display_height}
												/>
											}
											<label className="text-center">Live Camera</label>
										</Stack>

										<div className="align-items-center d-flex justify-content-center">	
											<Button id="onboard-facial-button-capture" onClick={camera_capture}>Take Photo</Button>
										</div>
									</>
								:	<>
										<Stack className="align-items-start d-flex justify-content-start mb-3 px-3" direction="vertical" gap={2}>
											<Form.Label>Please acknowledge the following to submit:</Form.Label>
											<Form.Check id="onboard-facial-checkbox-me">
												<Form.Check.Input required type="checkbox" />
												<Form.Check.Label htmlFor="onboard-facial-checkbox-me">I confirm that the person in this photo is me.</Form.Check.Label>
											</Form.Check>
											<Form.Check id="onboard-facial-checkbox-visible">
												<Form.Check.Input required type="checkbox" />
												<Form.Check.Label htmlFor="onboard-facial-checkbox-visible">I confirm that my facial features are clearly visible in this photo.</Form.Check.Label>
											</Form.Check>
										</Stack>
										
										<div className="align-items-center d-flex justify-content-center">
											<Image id="onboard-facial-image" src={image} />
										</div>
										<label className="mb-4 text-center">Captured Image</label>
										
										<div className="align-items-center d-flex justify-content-center">	
											<Button id="onboard-facial-button-capture" onClick={() => setCapture(true)}>Retake Photo</Button>
										</div>
									</>
								}
							</Row>
						</>
					}
				</Container>
				
				<Container className="mt-4 pt-4">
					<Row>
						<Col className={ window.matchMedia( "(max-width: 480px)" ).matches ? "d-flex justify-content-center" : "d-flex justify-content-end"}>
							{	( facial === image || capture )
							?	<Button disabled id="onboard-next-button">Next: Your Details</Button>
							:	<Button id="onboard-next-button" type="submit">Next: Your Details</Button>
							}
						</Col>
					</Row>
				</Container>
			</Form>
			
			<Modal centered onHide={() => setAlert( previous => false )} show={alert}>
				<Modal.Header className="pt-3 pb-1" closeButton id="onboard-warning-modal-header">
					<Modal.Title>
						<Stack direction="horizontal" gap={1}>	
							<PiWarningCircleBold color="#E46153" size={24} />
							
							<label id="onboard-warning-modal-header-text">Please Retake Your Photo</label>
						</Stack>
					</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<Stack direction="vertical" gap={3}>
						<label className="px-2">Your photo did not meet the requirements.</label>
							
						<Stack className="px-2" gap={2}>
							<label>Please refer to the below guidelines.</label>
							<Stack direction="horizontal" gap={2}>
							<Image src={facial_correct_1} />
								<label>Be in well-lit environment</label>
							</Stack>
							<Stack direction="horizontal" gap={2}>
								<Image src={facial_correct_2} />
								<label>Plain and white background</label>
							</Stack>
							<Stack direction="horizontal" gap={2}>
								<Image src={facial_correct_3} />
								<label>Facial features must be seen in full front view</label>
							</Stack>
							<Stack direction="horizontal" gap={2}>
								<Image src={facial_correct_4} />
								<label>Keep a neutral expression with mouth closed</label>
							</Stack>
						</Stack>
					</Stack>
				</Modal.Body>
			</Modal>
		</>
	)
}