import React , { createContext } from "react";

//Customer context
export const CustomerContext = createContext
	(   {	ClearCustomerInfo: () => {}
		,	GetCustomerInfo: () => {}
		,	SetCustomerInfo: () => {}
		,	SetCustomerPayment: () => {}
 		}
	)

export default function Customer( {children} )
{
	//Value 
    const CustomerContextValue = 
		{   ClearCustomerInfo
		,	GetCustomerInfo
		,	SetCustomerInfo
		}

	function ClearCustomerInfo()
	{
		//Log
		console.log( "Cleared session storage!")

		//Clear local storage
		sessionStorage.clear()
	}

	function GetCustomerInfo( key )
	{
		//Return key
		return ( null !== sessionStorage.getItem( key ) ? sessionStorage.getItem( key ) : "" )
	}

	function SetCustomerInfo( key , value )
	{
		//Set customer info 
		sessionStorage.setItem( key , value )
	}

	//Return context
    return (
        <CustomerContext.Provider value={CustomerContextValue}>
			{children}
        </CustomerContext.Provider>
    )
}