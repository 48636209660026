import React from "react";
import { Container } from "react-bootstrap";
import { BrowserRouter , Route , Routes } from "react-router-dom";

import Customer from "./customer_context";
import CustomerOnboardComplete from "./component/customer_onboard_complete";
import CustomerOnboardDetail from "./component/customer_onboard_details";
import CustomerOnboardFacial from "./component/customer_onboard_facial";
import CustomerOnboardPayment from "./component/customer_onboard_payment";
import Privacy from "./page/privacy";
import Home from "./component/home";
import HomeTopbar from "./component/home_topbar";

function App() {
	return (
		<Customer>
			<Container fluid>
				<BrowserRouter>
					<HomeTopbar />
					<Routes>
						<Route element={<Home />} path="/" />
						
						<Route element={<><CustomerOnboardComplete /></>} path="/customer/onboard/complete" />
						<Route element={<><CustomerOnboardDetail /></>} path="/customer/onboard/detail" />
						<Route element={<><CustomerOnboardFacial /></>} path="/customer/onboard/facial" />
						<Route element={<><CustomerOnboardPayment /></>} path="/customer/onboard/payment" />

						<Route element={<Privacy />} path="/privacy" /> 
					</Routes>
				</BrowserRouter>
			</Container>
		</Customer>
	)
}

export default App;
