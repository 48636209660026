import axios from "axios";
import React , { useContext , useState } from "react";
import { Button , Container , Col , Modal , Row } from "react-bootstrap";
import { MdHome } from "react-icons/md"
import { useNavigate } from "react-router-dom";

import { ConfigBackendRoute , ConfigFrontendRoute } from "../config";
import { CustomerContext } from "../customer_context";

export default function HomeTopbar() 
{
	//Contexts
	const customer_context = useContext( CustomerContext )

	//States
	const [alert, setAlert] = useState( false )

	//Use
	const navigate = useNavigate()

	function HandleAgreeButton()
	{
		//Clear customer info
		customer_context.ClearCustomerInfo()
			
		//Close alert
		setAlert( previous => false )

		//Navigate to root
		navigate( ConfigFrontendRoute.route_home_root , { replace: true } )
	}

	function HandleHomeButton()
	{
		//Display alert
		setAlert( previous => true )
	}

	return (
		<>
			<Container className="mb-4">
				<Row>
					<Col className="align-items-center d-flex justify-content-start">
						<label className="text-nowrap" id="topbar-label-location">JTC SUMMIT</label>
					</Col>
					<Col className="align-items-center d-flex justify-content-end">
						<Button onClick={HandleHomeButton} variant="light">
							<MdHome size={35} />
						</Button>
					</Col>
				</Row>
				<Row>
					<Col xl={2}>	
						<label id="topbar-label-product">EOT Services</label>
					</Col>
					<Col className="align-items-center d-flex">
						<div id="topbar-line-hr" />
					</Col>
				</Row>
			</Container>
			
			<Modal centered onHide={() => setAlert( previous => false )} show={alert}>
				<Modal.Header closeButton>
					<Modal.Title>Warning</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>You are currently in the midst of registration, your details will not be saved if you return to homepage.</p>
					<p>Click Agree to go back to homepage.</p> 
					<p>Click Disagree to continue with registration.</p>
				</Modal.Body>
				<Modal.Footer>
					<Container>
						<Row>
							<Col className="align-items-center d-flex justify-content-center">
								<Button onClick={HandleAgreeButton}>Agree</Button>
							</Col>
							<Col className="align-items-center d-flex justify-content-center">
								<Button onClick={() => setAlert( previous => false)}>Disagree</Button>
							</Col>
						</Row>
					</Container>
				</Modal.Footer>
			</Modal>
		</>
	)
}