import React, { useContext , useEffect , useState } from "react"
import { Button , Col , Container , Image , Row , Stack , Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";

import { ConfigFrontendRoute } from "../config";
import { CustomerContext } from "../customer_context";
import CustomerOnboardFlow from "./customer_onboard_flow";

export default function CustomerOnboardComplete()
{
	//Contexts
	const customer_context = useContext( CustomerContext )

	//States
	const [customerPayment , setCustomerPayment] = useState()

	useEffect
	(	() =>
		{	
			//Start refresh countdown
			const temp_refresh = setInterval
			(	() => 
				{
					//Log
					console.log( 'Waiting for customer info population' )
					
					//Temporary store info
					let temp_customer_payment = customer_context.GetCustomerInfo( 'stripe_payment') 
					
					//Check if info has data
					if ( 0 < temp_customer_payment.length )
					{
						//Set customer payment
						setCustomerPayment( previous => JSON.parse( customer_context.GetCustomerInfo( 'stripe_payment')  ) )

						//Clear interval 
						clearInterval( temp_refresh )
					}//end if
				}
			,	1000
			)
		}
	,	[]
	)

	//Return loading page if request has not respond
	if ( undefined === customerPayment ) 
	{
		//Return element
		return (
			<>	
				<Container>
					<Row className="d-flex justify-content-center">
						<Spinner animation="grow" />
					</Row>
				</Container>
			</>
		)
	}//end if statement

	//Return page after request has responded
	return (
		<>
			<CustomerOnboardFlow />
		
			{	window.matchMedia( "(max-width: 480px)" ).matches
			?	<Container className="my-3 p-5" id="form-customerOnboard-group">
					<Stack className="mb-3">
						<label id="onboard-label-header" style={{color: "#14AE5C"}}>Registration Complete!</label>
					</Stack>
					<Stack className="mb-3">
						<label id="onboard-label-subHeader">Submitted Image</label>
						<Image id="onboard-complete-image" src={customer_context.GetCustomerInfo( "image" )} />
					</Stack>
					<Stack className="mb-3">
						<label id="onboard-label-subHeader">Personal Information</label>
						<div>First Name:</div><div>{customer_context.GetCustomerInfo( "temp_name_first" )}</div>
						<div>Last Name:</div><div>{customer_context.GetCustomerInfo( "temp_name_last" )}</div>
						<div>Mobile Number:</div><div>{customer_context.GetCustomerInfo( "number_mobile" )}</div>
						<div>Email:</div><div>{customer_context.GetCustomerInfo( "email" )}</div>
					</Stack>
					<Stack>
						<label id="onboard-label-subHeader">Payment Details</label>
						<div>Verified by Stripe</div>
						<div>Card Number:</div><div>****{customerPayment.card.last4}</div>
						<div>Card Expiry:</div><div>{customerPayment.card.exp_month}/{customerPayment.card.exp_year}</div>
						<div>
							Card Type: 
							<br /> 
							{ String( customerPayment.card.brand ).charAt(0).toUpperCase() + String( customerPayment.card.brand ).slice(1) }
						</div>
					</Stack>
				</Container>
			:	<Container className="my-3 p-5" id="form-customerOnboard-group">
					<Row className="mb-4">
						<label id="onboard-label-header" style={{color: "#14AE5C"}}>Registration Complete!</label>
					</Row>
					<Row className="mb-3">
						<Col>
							<label id="onboard-label-subHeader">Captured Image</label>
						</Col>
						<Col>
							<label id="onboard-label-subHeader">Personal Information</label>
						</Col>
						<Col>
							<label id="onboard-label-subHeader">Payment Details</label>
						</Col>
					</Row>
					<Row>
						<Col>
							<Image id="onboard-complete-image" src={customer_context.GetCustomerInfo( "image" )} />
						</Col>	
						<Col>
							<Stack gap={2}>
								<div>First Name:</div><div>{customer_context.GetCustomerInfo( "temp_name_first" )}</div>
								<div>Last Name:</div><div>{customer_context.GetCustomerInfo( "temp_name_last" )}</div>
								<div>Mobile Number:</div><div>{customer_context.GetCustomerInfo( "number_mobile" )}</div>
								<div>Email:</div><div>{customer_context.GetCustomerInfo( "email" )}</div>
							</Stack>
						</Col>
						<Col>
							<Stack gap={2}> 
								<div>Verified by Stripe</div>
								<div>Card Number:</div><div>****{customerPayment.card.last4}</div>
								<div>Card Expiry:</div><div>{customerPayment.card.exp_month}/{customerPayment.card.exp_year}</div>
								<div>
									Card Type: 
								</div>
								<div>
									{ String( customerPayment.card.brand ).charAt(0).toUpperCase() + String( customerPayment.card.brand ).slice(1) }
								</div>
							</Stack>
						</Col>
					</Row>
				</Container>
			}
			
			<Container className="my-3 pt-4">
				<Row>
					<Col className={ window.matchMedia( "(max-width: 480px)" ).matches ? "d-flex justify-content-center" : "d-flex justify-content-end"}>
						<Link to={ConfigFrontendRoute.route_home_root} replace={true}>
							<Button id="onboard-next-button">Back To Home</Button>
						</Link>
					</Col>
				</Row>
			</Container>
		</>
	)
}