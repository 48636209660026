import React, { useContext } from "react"
import { Button , Col , Container , Row } from "react-bootstrap"
import { MdPersonAddAlt1 } from "react-icons/md"
import { Link } from "react-router-dom"

import "../style.css"

import { CustomerContext } from "../customer_context";

export default function Home() 
{
	//Get customer context
	const customer_context = useContext( CustomerContext )
	
	return (
		<Container>
			{customer_context.ClearCustomerInfo()}
			<Row>
				<Col className="align-items-center d-flex justify-content-center">
					<Link to="/customer/onboard/facial" replace={true}>
						<Button id="home-button-register">
							<MdPersonAddAlt1 id="home-button-icon" />
							<div className="text-center">Register Customer</div>
						</Button>
					</Link>
				</Col>
			</Row>
		</Container>
	)
}