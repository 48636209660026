import React, { useEffect , useState } from "react";
import { Col , Container , Row } from "react-bootstrap";
import { IoMdCheckmark } from "react-icons/io"
import { useLocation } from "react-router-dom";

import { ConfigFrontendRoute } from "../config";

export default function CustomerOnboardFlow()
{
	//States
	const [currentPath , setCurrentPath] = useState('')

	//Use
	const location = useLocation()

	useEffect(() => {
		//Set current path for tracking
		setCurrentPath( location.pathname )
	}, [location])
	
	function CreateCustomerFlow()
	{
		//Switch case to handle flow for different routes
		switch( currentPath )
		{
			//Case for /customer/onboard/facial route
			case ConfigFrontendRoute.route_onboard_facial:
			{	
				//Return html
				return (
					window.matchMedia( "(max-width: 480px)" ).matches
					?	<>
							<Col className="align-items-center d-flex justify-content-start">
								<label id="onboard-flow-label-currentNumber">1</label>
								<label id="onboard-flow-label-currentWord">Facial Recognition</label>								
							</Col>
						</>
					:	<>
							<Col className="align-items-center d-flex justify-content-center">
								<label id="onboard-flow-label-currentNumber">1</label>
								<label id="onboard-flow-label-currentWord">Facial Recognition</label>
							</Col>
							<Col className="align-items-center d-flex justify-content-center">
								<div id="onboard-flow-div-pending"/>
							</Col>
							<Col className="align-items-center d-flex justify-content-center">
								<label id="onboard-flow-label-pendingNumber">2</label>
								<label id="onboard-flow-label-pendingWord">Personal Information</label>
							</Col>
							<Col className="align-items-center d-flex justify-content-center"> 
								<div id="onboard-flow-div-pending"/>
							</Col>
							<Col className="align-items-center d-flex justify-content-center">
								<label id="onboard-flow-label-pendingNumber">3</label>
								<label id="onboard-flow-label-pendingWord">Payment Details</label>							
							</Col>
						</>
				)
			}//end /customer/onboard/facial case

			//Case for /customer/onboard/detail route
			case ConfigFrontendRoute.route_onboard_detail:
			{	
				//Return html
				return (
					window.matchMedia( "(max-width: 480px)" ).matches
					?	<>
							<Col className="align-items-center d-flex justify-content-start">
								<label id="onboard-flow-label-currentNumber">2</label>
								<label id="onboard-flow-label-currentWord">Personal Information</label>
							</Col>
						</>
					:	<>
							<Col className="align-items-center d-flex justify-content-center">
								<label id="onboard-flow-label-successNumber"><IoMdCheckmark /></label>
								<label id="onboard-flow-label-successWord">Facial Recognition</label>
							</Col>
							<Col className="align-items-center d-flex justify-content-center">
								<div id="onboard-flow-div-success"/>
							</Col>
							<Col className="align-items-center d-flex justify-content-center">
								<label id="onboard-flow-label-currentNumber">2</label>
								<label id="onboard-flow-label-currentWord">Personal Information</label>
							</Col>
							<Col className="align-items-center d-flex justify-content-center"> 
								<div id="onboard-flow-div-pending"/>
							</Col>
							<Col className="align-items-center d-flex justify-content-center">
								<label id="onboard-flow-label-pendingNumber">3</label>
								<label id="onboard-flow-label-pendingWord">Payment Details</label>							
							</Col>
						</>
				)
			}//end /customer/onboard/detail case

			//Case for /customer/onboard/payment route
			case ConfigFrontendRoute.route_onboard_payment:
			{	
				//Return html
				return (
					window.matchMedia( "(max-width: 480px)" ).matches
					?	<>
							<Col className="align-items-center d-flex justify-content-start">
								<label id="onboard-flow-label-currentNumber">3</label>
								<label id="onboard-flow-label-currentWord">Payment Details</label>
							</Col>
						</>
					:	<>
							<Col className="align-items-center d-flex justify-content-center">
								<label id="onboard-flow-label-successNumber"><IoMdCheckmark /></label>
								<label id="onboard-flow-label-successWord">Facial Recognition</label>
							</Col>
							<Col className="align-items-center d-flex justify-content-center">
								<div id="onboard-flow-div-success"/>
							</Col>
							<Col className="align-items-center d-flex justify-content-center">
								<label id="onboard-flow-label-successNumber"><IoMdCheckmark /></label>
								<label id="onboard-flow-label-successWord">Personal Information</label>
							</Col>
							<Col className="align-items-center d-flex justify-content-center"> 
								<div id="onboard-flow-div-success"/>
							</Col>
							<Col className="align-items-center d-flex justify-content-center">
								<label id="onboard-flow-label-currentNumber">3</label>
								<label id="onboard-flow-label-currentWord">Payment Details</label>							
							</Col>
						</>
				)
			}//end /customer/onboard/payment case

			//Case for /customer/onboard/complete route
			case ConfigFrontendRoute.route_onboard_complete:
			{	
				//Return html
				return (
					window.matchMedia( "(max-width: 480px)" ).matches
					?	<>
							<Col className="align-items-center d-flex justify-content-start">
								<label id="onboard-flow-label-currentNumber">4</label>
								<label id="onboard-flow-label-currentWord">Registration Complete!</label>	
							</Col>
						</>
					:	<>
							<Col className="align-items-center d-flex justify-content-center">
								<label id="onboard-flow-label-successNumber"><IoMdCheckmark /></label>
								<label id="onboard-flow-label-successWord">Personal Information</label>
							</Col>
							<Col className="align-items-center d-flex justify-content-center">
								<div id="onboard-flow-div-success"/>
							</Col>
							<Col className="align-items-center d-flex justify-content-center">
								<label id="onboard-flow-label-successNumber"><IoMdCheckmark /></label>
								<label id="onboard-flow-label-successWord">Payment Details</label>
							</Col>
							<Col className="align-items-center d-flex justify-content-center"> 
								<div id="onboard-flow-div-success"/>
							</Col>
							<Col className="align-items-center d-flex justify-content-center">
								<label id="onboard-flow-label-successNumber"><IoMdCheckmark /></label>
								<label id="onboard-flow-label-successWord">Facial Recognition</label>							
							</Col>
						</>
				)
			}//end /customer/onboard/complete case

			//Default case
			default:
			{
				//Break case
				break;
			}
		}//end switch
	}

	return (
		<Container className="mb-4">
			<Row>
				<CreateCustomerFlow />
			</Row>
		</Container>
	)
}